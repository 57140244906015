
// document.addEventListener("DOMContentLoaded", function () {

var selectedId = 0;
var flowList = [];
var listeners = {};

//Email group
var widgetsList = [];
var widgetGroup = {};
widgetGroup.name = "EMAIL";
var itemsList = [];
var item = {};
item.name = "send email";
item.icon = "fa-envelope-open-text";
item.preceedingIcon = "fas";
item.type = "FS_EMAIL";
item.variables = "variable_whatsapp_public_id,variable_whatsapp_secret,variable_whatsapp_template_name,variable_whatsapp_template_namespace,variable_whatsapp_recipient,variable_whatsapp_message_text,save-button";
item.preDefinedVariables=[];
item.mandatoryParent = '';
item.childLinkedElements = [];
itemsList.push(item);


widgetGroup.itemsList = itemsList;
widgetsList.push(widgetGroup);

//flow control group
widgetGroup = {};
widgetGroup.name = "flow control";

itemsList = [];

item = {};
item.name = "branch";
item.icon = "fa-code-branch";
item.preceedingIcon = "fas";
item.type = "FS_BRANCH";
item.variables = "variable_branch_variable,variable_branch_operator,variable_branch_value,variable_branch_component,variable_branch_else_component,add-branch-button";
item.preDefinedVariables=[];
item.mandatoryParent = '';
item.childLinkedElements = [];
itemsList.push(item);

item = {};
item.name = "branch";
item.icon = "fa-code-branch";
item.skip = true;
item.preceedingIcon = "fas";
item.type = "branch_widget";
item.variables = "variable_branch_variable,variable_branch_operator,variable_branch_value,variable_branch_component,variable_branch_else_component,add-branch-button";
item.preDefinedVariables=[];
item.mandatoryParent = '';
item.childLinkedElements = [];
itemsList.push(item);



item = {};
item.name = "go to flow";
item.icon = "fa-code-branch";
item.preceedingIcon = "fas";
item.type = "FS_GO_TO_FLOW";
item.variables = "variable_goto_process_definition_id,save-button";
item.preDefinedVariables=[];
item.mandatoryParent = '';
item.childLinkedElements = [];
itemsList.push(item);

item = {};
item.name = "jump to";
item.icon = "fa-forward";
item.preceedingIcon = "fas";
item.type = "FS_JUMP_TO";
item.variables = "variable_jump_to_max_loop_count,variable_jump_to_destination_type,variable_jump_to_destination_id,variable_jump_to_destination_color,jump-to-destination,save-button";
item.preDefinedVariables=[];
item.mandatoryParent = '';
item.childLinkedElements = [];
itemsList.push(item);

item = {};
item.name = "set variables";
item.icon = "fa-code";
item.preceedingIcon = "fas";
item.type = "FS_SET_VARIABLES";
item.variables = "variable-sender-id,save-button";
item.preDefinedVariables=[];
item.mandatoryParent = '';
item.childLinkedElements = [];
itemsList.push(item);

item = {};
item.name = "wait";
item.icon = "fa-hourglass-start";
item.preceedingIcon = "fas";
item.type = "FS_TIMER_WAIT";
item.variables = "variable_wait_seconds,variable_wait_minutes,variable_wait_hours,save-button";
item.preDefinedVariables=[];
item.mandatoryParent = '';
item.childLinkedElements = [];
itemsList.push(item);


item = {};
item.name = "end";
item.icon = "fa-stop";
item.preceedingIcon = "fas";
item.type = "FS_END";
item.variables = "";
item.preDefinedVariables=[];
item.mandatoryParent = '';
item.childLinkedElements = [];
itemsList.push(item);

widgetGroup.itemsList = itemsList;
widgetsList.push(widgetGroup);

//functions group
widgetGroup = {};
widgetGroup.name = "functions";

itemsList = [];

item = {};
item.name = "Api request";
item.icon = "fa-laptop-code";
item.preceedingIcon = "fas";
item.type = "FS_API_CALL";
item.variables = "variable_api_request,variable_api_call_url,variable_api_call_authentication_type,variable_api_call_header_list,variable_api_call_body,variable_api_call_method,save-button";
item.preDefinedVariables=[];
item.mandatoryParent = '';
item.childLinkedElements = [];
itemsList.push(item);

item = {};
item.name = "webhook";
item.icon = "fa-th-large";
item.preceedingIcon = "fas";
item.type = "FS_WAIT_FOR_WEBHOOK";
item.variables = "variable_wait_for_webhook_seconds,save-button";
item.preDefinedVariables=[];
item.mandatoryParent = '';
item.childLinkedElements = [];
itemsList.push(item);

widgetGroup.itemsList = itemsList;
widgetsList.push(widgetGroup);

//SMS group

widgetGroup = {};
widgetGroup.name = "sms";
itemsList = [];
item = {};
item.name = "send sms";
item.icon = "fa-sms";
item.preceedingIcon = "fas";
item.type = "FS_SMS";
item.variables = "variable_sms_app_id,variable_sms_sender_id,variable_sms_recipient,variable_sms_message_text,save-button";
item.preDefinedVariables=[];
item.mandatoryParent = '';
item.childLinkedElements = [];
itemsList.push(item);

item = {};
item.name = "inbound sms";
item.icon = "fa-comments";
item.preceedingIcon = "fas";
item.type = "FS_SAY_TEXT";
item.variables = "variable-sender-id,save-button";
item.preDefinedVariables=[];
item.mandatoryParent = '';
item.childLinkedElements = [];
itemsList.push(item);

widgetGroup.itemsList = itemsList;
widgetsList.push(widgetGroup);

// messaging group
widgetGroup = {};
widgetGroup.name = "messaging";

itemsList = [];
item = {};
item.name = "Send Whatsapp template";
item.icon = "fa-whatsapp";
item.preceedingIcon = "fab";
item.type = "FS_WHATSAPP";
item.variables = "variable_whatsapp_public_id,variable_whatsapp_secret,variable_whatsapp_template_name,variable_whatsapp_template_namespace,variable_whatsapp_recipient,variable_whatsapp_message_text,save-button";
item.preDefinedVariables=[];
item.mandatoryParent = '';
item.childLinkedElements = [];
itemsList.push(item);

item = {};
item.name = "send facebook message";
item.icon = "fa-facebook";
item.preceedingIcon = "fab";
item.type = "FS_FACEBOOK";
item.variables = "variable-sender-id,variable-destination-number,variable-message-body,save-button";
item.preDefinedVariables=[];
item.mandatoryParent = '';
item.childLinkedElements = [];
itemsList.push(item);


widgetGroup.itemsList = itemsList;
widgetsList.push(widgetGroup);


//voice group
widgetGroup = {};
widgetGroup.name = "Voice";

itemsList = [];
item = {};
item.name = "Make a call";
item.icon = "fa-phone-volume";
item.preceedingIcon = "fas";
item.type = "FS_VOICE";
item.variables = "variable_voice_application_key,variable_voice_application_name,variable_voice_caller_id,variable_voice_call_destination,save-button";
item.preDefinedVariables=['voice_call_status'];
item.mandatoryParent = '';
item.childLinkedElements = ['FS_VOICE_END_CALL', 'FS_VOICE_COLLECT_INPUT', 'FS_PLAY_AUDIO'];
itemsList.push(item);

item = {};
item.name = "play audio";
item.icon = "fa-microphone";
item.preceedingIcon = "fas";
item.type = "FS_PLAY_AUDIO";
item.variables = "variable_voice_play_audio_url,variable_voice_play_audio_loop_count,variable_voice_play_audio_name,save-button";
item.preDefinedVariables=[];
item.mandatoryParent = 'FS_VOICE';
item.childLinkedElements = [];
itemsList.push(item);

item = {};
item.name = "Collect Input";
item.icon = "fa-microphone";
item.preceedingIcon = "fas";
item.type = "FS_VOICE_COLLECT_INPUT";
item.variables = "variable_voice_collect_play_audio_url,variable_voice_collect_play_audio_name,variable_voice_collect_wait_for_response_seconds,save-button";
item.preDefinedVariables=['collected_digit'];
item.mandatoryParent = 'FS_VOICE';
item.childLinkedElements = [];
itemsList.push(item);

item = {};
item.name = "End call";
item.icon = "fa-microphone";
item.preceedingIcon = "fas";
item.type = "FS_VOICE_END_CALL";
item.variables = "";
item.preDefinedVariables=[];
item.mandatoryParent = 'FS_VOICE';
item.childLinkedElements = [];
itemsList.push(item);

widgetGroup.itemsList = itemsList;
widgetsList.push(widgetGroup);


var branchData = {};
branchData.nodes = [];
var node = {};
node.variable = "name";
node.operator = ">";
node.value = "3";
node.component = { index: 75654645, name: "send sms", type: "FS_VOICE" }
branchData.nodes.push(node);

node = {};
node.variable = "else";
node.operator = "execute";
node.value = "";
node.component = { index: 747742894, name: "send sms", type: "FS_VOICE" }
branchData.nodes.push(node);



var variableConverterPlaceHolder = {};
variableConverterPlaceHolder.frontendStart = "<span class='variable_mask' contenteditable='false'>";
variableConverterPlaceHolder.frontendStartFormatted = '<span class=\"variable_mask\" contenteditable=\"false\">';
variableConverterPlaceHolder.frontendStartStyle = '<span class=\"variable_mask\" contenteditable=\"false\" style=\"\">';
variableConverterPlaceHolder.frontendFinish = '</span>.';
variableConverterPlaceHolder.backendStart = '{{';
variableConverterPlaceHolder.backendFinish = '}}';

var jumpColorArray = ["black", "red", "blue", "orange", "yellow"];


var canvas_div = document.getElementById("canvas");

var flow = localStorage.getItem("flowList");
flow = JSON.parse(flow);
if (canvas_div) {
    let triggerType = localStorage.getItem('triggerType');
    rebuildFlow(flow, triggerType);
    initiateAccordion();
}

var parentWidgetType = null;
//rescaleCanvas();
function rebuildFlow(flow, widgetType) {
    if (typeof flow !== 'object') {
        flow = JSON.parse(flow);
    }
    canvas_div = document.getElementById("canvas");
    canvas_div.innerHTML = "";
    var div = document.getElementById("canvas");
    while (div.firstChild) {
        div.removeChild(div.firstChild);
    }

    if (flow && flow.length == 0) {
        addComponentToStorage(100, widgetType, null);
        return;
    }


    //create trigger component
    var parent = setUpStartComponent(flow[0]);

    if (parent === null) {
        return false;
    }

    parent = addPlusElement(parent, 100);
    canvas_div.appendChild(parent);
    if (flow && flow.length > 1) {
        rebuilding = true;
        var parent = document.getElementById("content__container_branch");

        createLink(parent);


        flow.map((item, i) => {
            widgetsList.filter(obj => {
                obj.itemsList.filter(ob => {

                    if (ob.type === item.type) {
                        i++;
                        if (i > 1) {
                            var parent = document.getElementById("content__container_branch");
                            if (item.type === "FS_BRANCH") {
                                branchWidget(parent, item);
                                addPlusElement(parent, item.index);
                            } else {
                                addComponent(true, parent, '--blue', ob.preceedingIcon, ob.icon, ob.name, item);

                                if (item.type !== "FS_END") {
                                    addPlusElement(parent, item.index);
                                }
                            }

                            if (flow.length > i) {
                                createLink(parent);
                            } else {
                                //addPlusElement(parent, item.index);
                            }
                        }
                    }
                })
            })

        });
        localStorage.setItem("flowList", JSON.stringify(flow))
        rebuilding = false;

    } else {
        flow = flow.length === 0 ? [] : flow;
        localStorage.setItem("flowList", JSON.stringify(flow));
    }
    initiateAccordion();
    dropTargetListener();
    rescaleCanvas();

    dragElement(document.getElementById(("canvas")));
}

function setUpStartComponent(widget) {

    for (let item of widgetsList) {
        let isFound = false;
        item.itemsList.filter(ob => {
            if (ob.type === widget.type) {
                isFound = true;
                widget.preceedingIcon = ob.preceedingIcon;
                widget.icon = ob.icon;
                widget.name = widget.name ? widget.name : ob.name;
            }
        });
        if (isFound) {
            break;
        }
    }


    var parent = document.createElement("DIV");
    parent.classList.add('content__container_branch');
    parent.setAttribute("id", "content__container_branch");
    var card = document.createElement("DIV");
    card.classList.add('content__container_branch_card');
    card.classList.add('card_min_width');

    var cardIconDiv = document.createElement("DIV");
    cardIconDiv.classList.add('content__container_branch_card_icon');
    cardIconDiv.classList.add('--fixed');

    var cardIconI = document.createElement("I");
    cardIconI.classList.add('fas');
    cardIconI.classList.add('fa-rocket');
    cardIconDiv.appendChild(cardIconI);
    card.appendChild(cardIconDiv);
    //
    //second card
    cardIconDiv = document.createElement("DIV");
    cardIconDiv.classList.add('content__container_branch_card_icon');//content__container_tools_call-box-icon
    cardIconDiv.style = "cursor: pointer";

    cardIconDiv.setAttribute("id", "card_properties_" + widget.index);

    cardIconDiv.addEventListener('click', (event) => showPropertiesView(event, card, widget, widget.name));

    cardIconI = document.createElement("I");
    cardIconI.classList.add(widget.preceedingIcon);
    cardIconI.classList.add(widget.icon);
    cardIconDiv.appendChild(cardIconI);

    card.appendChild(cardIconDiv);

    var cardh1 = document.createElement("H1");
    cardh1.classList.add('content__container_branch_card_title');
    cardh1.innerText = widget.name + " Trigger";
    card.appendChild(cardh1);

    parent.appendChild(card);

    return parent;
}

function addPlusElement(parent, index) {
    //removeElementById("add_element")

    var addElement = document.createElement("DIV");
    addElement.classList.add('content__container_tools_icon');
    addElement.classList.add('droptarget');
    addElement.setAttribute("name", "add_element ");
    addElement.setAttribute("id", "add_element " + "index_" + index);
    addElement.setAttribute("title", index);

    cardIconI = document.createElement("I");
    cardIconI.classList.add('fas');
    cardIconI.classList.add('fa-plus');

    addElement.appendChild(cardIconI);
    parent.appendChild(addElement);
    addElement.addEventListener('click', function () {
        /* do stuff here*/
        // createLink();
        var thisId = this.title;
        createWidgetModal(parent, thisId);
        initiateAccordionWidget();

    }, false);

    dropTargetListener();

    return parent;
}

function createLink(parent) {

    //removeElementById("add_element");

    var arrowParent = document.createElement("DIV");
    arrowParent.classList.add('content__container_branch_arrow');
    var arrowChild = document.createElement("DIV");
    arrowChild.classList.add('content__container_branch_arrow_dot');
    arrowParent.appendChild(arrowChild);
    parent.appendChild(arrowParent);
}

function revertSelectedWidget(id) {
    document.querySelectorAll('.content__container_branch_card').forEach(element => {
        element.style = "padding: 0 0 0 0;";
    });

}

function addComponent(appendToParent, parent, color, preceedingIcon, icon, name, item) {

    var index = item.index;
    var type = item.type;
    var data = item.data;
    if (!item.skip && type === "FS_BRANCH") {
        branchWidget(parent, item);
    } else {
        var branch = document.createElement("DIV");
        branch.classList.add('content__container_branch_card');
        branch.setAttribute("id", index);
        branch.style = "padding: 0 0 0 0;";

        //add component icon
        var cardIconDiv = document.createElement("DIV");
        cardIconDiv.classList.add('content__container_branch_card_icon');
        cardIconDiv.classList.add(color);
        cardIconDiv.setAttribute("id", "card_properties_" + index);
        cardIconDiv.style = "cursor: pointer";

        var cardIconI = document.createElement("I");
        cardIconI.classList.add(preceedingIcon);
        cardIconI.classList.add(icon);

        selectedId = index;

        cardIconDiv.addEventListener('click', (event) => showPropertiesView(event, branch, item, name));


        cardIconDiv.appendChild(cardIconI);

        var color = 'red';

        //for the destinationId
        if (item && item.data && item.data.color) {
            var circle = document.createElement("div");
            circle.setAttribute("id", "jump_to_" + index);

            circle.insertAdjacentHTML('beforeend', '<svg viewBox="0 0 21 8" xmlns="http://www.w3.org/2000/svg" class="jump_to_arrow"><g transform="translate(1)" fill="none" fill-rule="evenodd"><g stroke="currentColor" stroke-linecap="round" stroke-width="2"><path stroke-linejoin="round" d="M5.828 1l2.829 2.828-2.829 2.829"></path><path d="M0 4h8"></path></g>'
                + '<circle style="color: ' + item.data.color + '" fill="currentColor" cx="16" cy="4" r="4"></circle>'
                + '</g></svg>');


            circle.onmouseover = function () {
                this.style.border = '2px solid ' + item.data.color;
                var jump_from = document.getElementById("jump_from_" + item.data.source_id);
                if (jump_from) {
                    jump_from.style.border = '2px solid ' + item.data.color;
                }
            }

            circle.onmouseleave = function () {
                this.style.border = "";
                var jump_from = document.getElementById("jump_from_" + item.data.source_id);
                if (jump_from) {
                    jump_from.style.border = '';
                }
            }

            branch.appendChild(circle);
        }

        branch.appendChild(cardIconDiv);

        var cardh1 = document.createElement("H1");
        cardh1.classList.add('content__container_branch_card_title');
        cardh1.innerText = item.name ? item.name : name;
        branch.appendChild(cardh1);

        //add trash icon
        var cardIconDiv = document.createElement("DIV");
        cardIconDiv.classList.add('content__container_branch_card_icon_left');
        cardIconDiv.classList.add(color);
        cardIconDiv.style = "cursor: pointer;display: flex;";
        cardIconDiv.setAttribute("id", index);
        cardIconDiv.setAttribute("name", "delete_icon");

        var cardIconI = document.createElement("I");
        cardIconI.classList.add("fa");
        cardIconI.classList.add("fa-trash");
        cardIconDiv.addEventListener('click', function () {
            /* do stuff here*/

        const thisId = this.id;
        let flow = localStorage.getItem("flowList");
        flow = JSON.parse(flow);

        let selectedWidgetType;
          let index = flow.findIndex(item => item.index === thisId);
          if (index === -1) {
            let isContainVoice = false;
            let branchData = flow.filter(obj => {
              if (obj.type === 'FS_BRANCH' && obj.nodes) {
                for (let node of obj.nodes) {
                  if (node.components) {
                    for (let component of node.components) {
                      if (component.type === 'FS_VOICE') {
                        isContainVoice = true;
                      }

                      if (component.index === thisId) {
                        return obj;
                      }
                    }
                  }
                }
              }
            });

            if (branchData.length > 0 && isContainVoice) {
              selectedWidgetType = 'FS_VOICE';
              index = flow.findIndex(item => item.index === branchData[0].index);
            }
          } else {
            selectedWidgetType = flow[index].type;
          }

          if(index === -1) {
            return ;
          }

          const selectedWidget = getWidgetByType(selectedWidgetType);
          let childLinkedElementExists = false;
          for (let i = index; i < flow.length; i++) {
            if (selectedWidget.childLinkedElements.length > 0 && selectedWidget.childLinkedElements.includes(flow[i].type)) {
              childLinkedElementExists = true;
              break;
            }
          }

          if (childLinkedElementExists) {
            alert("There are linked child elements. Please remove them.");
            return false;
          }

            flow = flow.filter(function (obj) {
                if (obj.index !== thisId) {
                    if ("FS_BRANCH" === obj.type) {
                        let nodes = obj.nodes && obj.nodes.filter(function (node) {
                            let components = node.components && node.components.filter(function (component) {
                                if (component.index != thisId)
                                    return component;
                            });
                            node.components = components;
                            return node.components && node.components.length > 1 ? node : null;
                        });
                    }

                    return obj;
                }
            });

            rebuildFlow(flow, parentWidgetType);
        }, false);

        cardIconDiv.appendChild(cardIconI);

        branch.appendChild(cardIconDiv);

        var color = 'red';

        //add go to selector icon
        var cardIconDiv = document.createElement("DIV");
        cardIconDiv.classList.add('content__container_branch_card_icon_left');
        cardIconDiv.classList.add('jump_to_icon');
        cardIconDiv.style = "cursor: pointer; display: none; border: 1px dotted " + color;
        cardIconDiv.setAttribute("id", index);
        cardIconDiv.setAttribute("name", "jump_to_icon");

        var cardIconI = document.createElement("I");
        cardIconI.classList.add("fa");
        cardIconI.classList.add("fa-forward");
        cardIconDiv.addEventListener('click', function () {
            /* do stuff here*/

            var thisId = this.id;
            showDeleteOrJumpToIcon('flex', 'none')

            document.getElementById("jump_to_destination_id").value = thisId;
            //get the component from flowList
            var component = getComponentByIndex(thisId);
            if (component) {
                document.getElementById("jump_to_destination_type").value = component.type;
                document.getElementById("jump_to_destination_color").value = getRandomColor();


            }





        }, false);

        cardIconDiv.appendChild(cardIconI);
        branch.appendChild(cardIconDiv);


        //for jump to component
        if (item && item.data && item.data.jump_to_destination_color) {
            var circle = document.createElement("div");
            circle.setAttribute("id", "jump_from_" + index);
            circle.insertAdjacentHTML('beforeend', '<svg viewBox="0 0 21 8" xmlns="http://www.w3.org/2000/svg" class="jump_to_arrow"><g transform="translate(1)" fill="none" fill-rule="evenodd"><g stroke="currentColor" stroke-linecap="round" stroke-width="2"><path stroke-linejoin="round" d="M5.828 1l2.829 2.828-2.829 2.829"></path><path d="M0 4h8"></path></g>'
                + '<circle style="color: ' + item.data.jump_to_destination_color + '" fill="currentColor" cx="16" cy="4" r="4"></circle>'
                + '</g></svg>');

            circle.onmouseover = function () {
                this.style.border = '2px solid ' + item.data.jump_to_destination_color;
                this.style.borderRadius = "5px";

                var jump_to = document.getElementById("jump_to_" + item.data.jump_to_destination_id);
                jump_to.style.border = '2px solid ' + item.data.jump_to_destination_color;
                jump_to.style.borderRadius = "5px";
            }

            circle.onmouseleave = function () {
                this.style.border = "";
                var jump_to = document.getElementById("jump_to_" + item.data.jump_to_destination_id);
                jump_to.style.border = '';
            }

            branch.appendChild(circle);
        }


        if (appendToParent) {
            parent.appendChild(branch);

        }
        return branch;
    }


}

function showPropertiesView(e, branch, item, name) {
    e.preventDefault();
    let index = item.index;
    let type = item.type;
    let data = item.data;
    localStorage.setItem("component", JSON.stringify(item));
    //revert previous selected widget to its previous state
    selectedId = index;

    revertSelectedWidget(selectedId);

    // console.log(branch.class?);
    //style selected widget
  if (branch) {
    branch.style = "padding: 0;border: 1px solid black";
    let elems = document.querySelectorAll(".selected-widget");

    [].forEach.call(elems, function (el) {
      el.classList.remove("selected-widget");
    });

    branch.classList.add('selected-widget');


    // content__container_actions_variables_heading active
    document.getElementById('variables_heading').classList.remove('active');
    document.getElementById('variables_info').style = 'display: none';
  }
    //show properties view
    let parent_properties = document.getElementById("parent_properties");
    parent_properties.style = "z-index: 1; visibility: visible;";


    document.getElementById('properties_name').innerText = item.name ? item.name : name;

    //time to decide the variables to show based on the widget selected
    var formProperties = document.getElementById("properties_id");
    if (type === "FS_JUMP_TO") {

        var button = document.createElement("button");

        button.classList.add('form_item_btn');
        button.setAttribute("id", "jump-to-destination" + index);
        button.setAttribute("name", "variable_input");
        button.innerText = "Select widget";

        button.addEventListener('click', function () {

            //
            showDeleteOrJumpToIcon('none', 'flex');

        }, false);

        formProperties.appendChild(button);
    }

    if (type !== "FS_BRANCH") {
        var save_button = document.createElement("button");

        save_button.classList.add('form_item_btn');
        save_button.setAttribute("id", "save-button" + index);
        save_button.setAttribute("name", "variable_input");
        save_button.innerText = "Save";
        formProperties.appendChild(save_button);

      save_button && save_button.addEventListener('click', function () {

        let data = {};
        let invalidInput = false;
        widgetVarriables.map((variable, i) => {

          if (variable.indexOf('variable_') >= 0) {

            variable = variable.substring('variable_'.length);
            let variableElement = document.getElementById(variable);
            let value = null;
            if (variableElement) {
              let isElementValid;
              if ("DIV" == variableElement.nodeName) {
                isElementValid = validateInput(variableElement, 'div');
                value = variableElement.innerHTML;
              } else {
                isElementValid = validateInput(variableElement, 'input');
                value = variableElement.value;
              }
              //transform to backend equivalent
              if (value) {
                value = convertValueToBackendFormat(value);
                data[variable] = value;
              } else {
                value = document.getElementById(variable).value;
                data[variable] = value;
              }
                if(!invalidInput && isElementValid) {
                  invalidInput = true;
                }
            }
          }
        });

        let flowList = localStorage.getItem("flowList");
        flowList = JSON.parse(flowList);

        //if this includes jump_to_destination_id, we need to tag the the destination with
        //color,and source_id
        if (data.jump_to_destination_id) {
          flowList = flowList.filter(function (obj) {

            obj = setUpJumpToData(obj, data);

            return obj;
          });
        }

        flowList = flowList.filter(function (obj) {

          obj = setUpComponentData(obj, index, data);
          return obj;
        });
        localStorage.setItem("flowList", JSON.stringify(flowList));

        //close properties view
        if (invalidInput === false) {
          var parent_properties = document.getElementById("parent_properties");
          parent_properties.style = "z-index: 1000; visibility: hidden;";
          revertSelectedWidget(selectedId);

          rebuildFlow(flowList, parentWidgetType);
        }

      });
    }


    if (type === "FS_BRANCH") {
        var add_branch_button = document.createElement("button");

        add_branch_button.classList.add('form_item_btn');
        add_branch_button.setAttribute("id", "add-branch-button" + index);
        add_branch_button.setAttribute("name", "variable_input");
        add_branch_button.innerText = "Add to branch";
        formProperties.appendChild(add_branch_button)

        add_branch_button && add_branch_button.addEventListener('click', function () {

            var node = {};
            node.variable = convertValueToBackendFormat(document.getElementById("branch_variable").innerHTML);
            node.operator = (document.getElementById("branch_operator").value);
            node.value = convertValueToBackendFormat(document.getElementById("branch_value").innerHTML);
            node.components = [];
            component = {};
            component.index = getRandomIndex();
            component.type = document.getElementById("branch_component").value;
            component = setComponentVariable(component);
            node.components.push(component);


            var elseNode = {};
            elseNode.variable = "else";
            elseNode.operator = "";
            elseNode.value = "";

            elseNode.components = [];
            component = {};
            component.index = getRandomIndex();
            component.type = document.getElementById("branch_else_component").value;
            component = setComponentVariable(component);
            elseNode.components.push(component);


            var flowList = localStorage.getItem("flowList");
            flowList = JSON.parse(flowList);

            flowList = flowList.filter(function (obj) {
                obj = setUpBranchNode(obj, index, node, elseNode);
                return obj;
            });

            localStorage.setItem("flowList", JSON.stringify(flowList));

            //close properties view
            var parent_properties = document.getElementById("parent_properties");
            parent_properties.style = "z-index: 1000; visibility: hidden;";
            revertSelectedWidget(selectedId);

            rebuildFlow(flowList, parentWidgetType);


        });
    }


    let variable_input = document.querySelectorAll("[name='variable_input']");
    variable_input.forEach((element) => {
        element.style.display = "none";
    });


    var widgetVarriables = [];

    widgetsList.filter(obj => {
        obj.itemsList.filter(ob => {
            if (ob.type === type) {

                var array = ob.variables.split(',');
                widgetVarriables = array;
                array.map((variable, i) => {



                    if (document.getElementById(variable) || document.getElementById(variable + index)) {


                        document.getElementById(variable + index) && document.getElementById(variable + index).style.removeProperty('display');
                        document.getElementById(variable) && document.getElementById(variable).style.removeProperty('display');


                        if (variable.indexOf('variable_') >= 0) {

                            // setup input data
                            var variableId = variable.substring('variable_'.length);
                            var variableInput = document.getElementById(variableId);
                            variableInput && variableInput.classList.add('droptargetVariable');

                            if (data && variableInput) {

                                var value = data[variableId]
                                if (value) {
                                    value = value.replaceAll(variableConverterPlaceHolder.backendStart, variableConverterPlaceHolder.frontendStart);
                                    value = value.replaceAll(variableConverterPlaceHolder.backendFinish, variableConverterPlaceHolder.frontendFinish);


                                    if ("DIV" == variableInput.nodeName) {
                                        variableInput.innerHTML = value;
                                    } else {
                                        variableInput.value = value;
                                    }
                                }

                            }

                                }
                            }
                        })

                        dropVariableTargetListener();
                    }
                })
            })


    //hide properties on click of close icon
    var close_properties = document.getElementById("close_properties");
    close_properties && close_properties.addEventListener('click', function () {

        closePropertiesView();

    }, false);

}

function setUpJumpToData(obj, data){

    if ("FS_BRANCH" === obj.type) {
        let nodes = obj.nodes && obj.nodes.filter(function (node) {

            let components = node.components && node.components.filter(function (component) {
                if (component.index == data.jump_to_destination_id) {
                    if (!component.data) {
                        component.data = {};
                    }
                    component.data.color = data.jump_to_destination_color;
                    component.data.source_id = selectedId;
                } else if ("FS_BRANCH" === component.type) {
                    component = setUpJumpToData(component,data);
                }else {
                    if (component.data && selectedId == component.data.source_id) {
                        component.data.color = null;
                    }
                }
                return component;
            });
            node.components = components;
            return node;
        });
        obj.nodes = nodes;
    }else if (obj.index == data.jump_to_destination_id) {
        if (!obj.data) {
            obj.data = {};
        }
        obj.data.color = data.jump_to_destination_color;
        obj.data.source_id = selectedId;
    } else {
        if (obj.data && selectedId == obj.data.source_id) {
            obj.data.color = null;
        }
    }
    return obj;
}

function setUpBranchNode(obj, index, primarynode, elseNode){

    if (obj.index === index) {
        //check if this branch has nodes
        if (obj.nodes && obj.nodes.length > 0) {
            obj.nodes.unshift(primarynode);
        } else {
            obj.nodes = [];
            obj.nodes.push(primarynode);
            obj.nodes.push(elseNode);
        }
    }else if ("FS_BRANCH" === obj.type) {
        var nodes = obj.nodes && obj.nodes.filter(function (node) {

            var components = node.components && node.components.filter(function (component) {

                if (component.index === index) {
                    if (component.nodes && component.nodes.length > 0) {
                        component.nodes.unshift(primarynode);
                    } else {
                        component.nodes = [];
                        component.nodes.push(primarynode);
                        component.nodes.push(elseNode);
                    }

                }else if ("FS_BRANCH" === component.type) {
                    component = setUpBranchNode(component,index, primarynode, elseNode);
                }
                return component;
            });
            node.components = components;
            return node;
        });
        obj.nodes = nodes;
    }
    return obj;
}

function setUpComponentData(obj, index,data){
    if ("FS_BRANCH" === obj.type) {
        var nodes = obj.nodes && obj.nodes.filter(function (node) {

            var components = node.components && node.components.filter(function (component) {
                if ("FS_BRANCH" === component.type) {
                    component = setUpComponentData(component, index,data);
                }
                else if (component.index == index) {
                    component.data = component.data ? component.data : {};
                    component.data = updateData(data, component.data);
                }
                return component;
            });
            node.components = components;
            return node;
        });
        obj.nodes = nodes;
    } else if (obj.index == index) {
        obj.data = obj.data ? obj.data : {};
        obj.data = updateData(data, obj.data);
    }
    return obj;
}

function closePropertiesView() {
    let parent_properties = document.getElementById("parent_properties");
    parent_properties.style = "z-index: 1000; visibility: hidden;";
    revertSelectedWidget(selectedId);
}

function updateWidgetName(index, name) {
    let flowList = JSON.parse(localStorage.getItem("flowList"));

    flowList = flowList.filter(function (obj) {
        if ("FS_BRANCH" === obj.type) {
            let nodes = obj.nodes && obj.nodes.filter(function (node) {

                let components = node.components && node.components.filter(function (component) {
                    if (component.index == index) {
                        component.name = name;
                    }
                    return component;
                });
                node.components = components;
                return node;
            });
            obj.nodes = nodes;
        }
        if (obj.index == index) {
            obj.name = name;
        }

        return obj;
    });

    localStorage.setItem("flowList", JSON.stringify(flowList));
    setTimeout(() => {
        rebuildFlow(flowList, localStorage.getItem("triggerType"));
        closePropertiesView();
    }, 400);
}
function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

function convertValueToBackendFormat(value) {
    value=value.replaceAll("<br>","");
    value = value.replaceAll(variableConverterPlaceHolder.frontendStartFormatted, variableConverterPlaceHolder.backendStart);
    value = value.replaceAll(variableConverterPlaceHolder.frontendStartStyle, variableConverterPlaceHolder.backendStart);
    value = value.replaceAll(variableConverterPlaceHolder.frontendStart, variableConverterPlaceHolder.backendStart);

    value = value.replaceAll(variableConverterPlaceHolder.frontendFinish, variableConverterPlaceHolder.backendFinish);
    value = value.replaceAll('&nbsp;', " ");
    return value;
}

function updateData(target, src) {
    const res = src;
    if (target) {
        Object.keys(target).forEach(k => res[k] = target[k]);
    }
    return res;
}

function showDeleteOrJumpToIcon(deleteStyle, jumpToStyle) {
    let delete_icon = document.querySelectorAll("[name='delete_icon']");
    delete_icon.forEach((element) => {
        element.style.display = deleteStyle;
    });
    let jump_to_icon = document.querySelectorAll("[name='jump_to_icon']");
    jump_to_icon.forEach((element) => {
        element.style.display = jumpToStyle;
    });
}

function addComponentToStorage(index, type, initIndex) {

    let item = {};
    item.index = index;
    item.type = type;
    item = setComponentVariable(item);
    if (index === 100) {
        item.trigger = true;
    }

    flowList = localStorage.getItem("flowList");
    flowList = JSON.parse(flowList);
    flowList = flowList ? flowList : [];

    indexInArray = 0;
    if (flowList.length > 0) {

        indexFound = false;
        if ((initIndex == 100)) {
            indexFound = true;
            indexInArray = 0;
        }
        flowList = flowList.map((obj, i) => {
            if (obj.index == initIndex) {
                indexFound = true;
                indexInArray = i + 1;
            }else{
                obj = setUpComponentArrangement(obj,initIndex,item);
            }


            return obj;
        });

        if (indexFound)
            flowList.splice(indexInArray, 0, item);
    } else {
        flowList.push(item);
    }
    localStorage.setItem("flowList", JSON.stringify(flowList))


    rebuildFlow(flowList, parentWidgetType);
}

function setComponentVariable(item){
    let widgetFound = false;
    for(let widgetGroup of widgetsList){
      for(let widget of widgetGroup.itemsList){
        if(widget.type == item.type){
          item.variables = widget.preDefinedVariables;
          widgetFound = true;
          break;
        }
      }
      if(widgetFound){
        break;
      }
    }
    return item;
}


function setUpComponentArrangement(obj,initIndex,item){

    if ("FS_BRANCH" === obj.type) {
        var nodes = obj.nodes && obj.nodes.map((node, j) => {

            indexFoundInBranch = false;
            components = node.components && node.components.map((component, k) => {
                if (component.index == initIndex) {
                    indexFoundInBranch = true;
                    indexInArray = k + 1;
                }else if ("FS_BRANCH" === component.type) {
                    component = setUpComponentArrangement(component,initIndex,item);
                }
                return component;
            });
            if (indexFoundInBranch) {
                components.splice(indexInArray, 0, item);
            }

            node.components = components;
            return node;


        });
        obj.nodes = nodes;

    }

    return obj;
}



function getComponentByIndex(index) {

    let result = null;
    flowList.map((obj, i) => {
        if (obj.index == index) {
            result = obj;
        }
        else if ("FS_BRANCH" === obj.type) {
            obj.nodes && obj.nodes.map((node, j) => {
                indexFoundInBranch = false;
                components = node.components && node.components.map((component, k) => {
                    if (component.index == index) {
                        result = component;
                    }
                });
            });
        }
    });
    return result;

}

function getWidgetByType(type){
    let widgetItem = null;
    if(widgetsList){
        widgetsList.filter(obj => {
            obj.itemsList.filter(ob => {
                if (ob.type === type) {
                    widgetItem = ob;
                }
            })
        })
    }

    return widgetItem;
}

function createWidgetModal(mainParent, initIndex) {


    var top_parent = document.createElement("DIV");
    top_parent.classList.add('content__container_tools');
    top_parent.setAttribute("id", "widget_modal");
    var parent = document.createElement("UL");
    parent.classList.add('content__container_tools_list');

    var li = document.createElement("LI");
    li.classList.add('content__container_tools_item');

    var h1 = document.createElement("H1");
    h1.classList.add('content__container_tools_item_title');
    h1.innerText = "widget";

    var i = document.createElement("I");
    i.classList.add('fas');
    i.classList.add('fa-times');

    i.addEventListener('click', function () {

        removeElementById("widget_modal")


    }, false);

    li.appendChild(h1);
    li.appendChild(i);

    parent.appendChild(li);
    for (let index = 0; index < widgetsList.length; ++index) {
        createWidgetList(mainParent, parent, widgetsList[index], initIndex);
    }

    top_parent.appendChild(parent)
    mainParent.appendChild(top_parent);


}


function createWidgetList(mainParent, parent, widget, initIndex) {
    var li = document.createElement("LI");

    var div = document.createElement("DIV");
    div.classList.add('content__container_widgets_tile');


    createWidgetItemHead(div, widget.name);
    createWidgetItemBodyPanel(mainParent, div, widget.itemsList, initIndex);
    li.appendChild(div)

    parent.appendChild(li);
}
function createWidgetItemHead(parent, name) {

    var div = document.createElement("DIV");
    div.classList.add('content__container_widgets_tile_header');
    div.classList.add('accordion_widget');

    let id = getRandomIndex();
    var input = document.createElement("input");
    input.setAttribute("id", id);
    input.setAttribute("type", "checkbox");
    input.style = "display: none";


    var h1 = document.createElement("H1");
    h1.innerText = name;
    h1.setAttribute("for", id);

    var i = document.createElement("I");
    i.classList.add('fas');
    i.classList.add('fa-plus');

    div.appendChild(input);
    div.appendChild(h1);
    div.appendChild(i);

    parent.appendChild(div);

}
function createWidgetItemBodyPanel(mainParent, parent, itemsList, initIndex) {

    var div = document.createElement("DIV");
    div.classList.add('panel_widget');

    for (let index = 0; index < itemsList.length; ++index) {
        let item = itemsList[index];
        if (!item.skip) {
            createWidgetItemBody(mainParent, div, item, initIndex);
        }

    }
    parent.appendChild(div);


    return parent;

}
function createWidgetItemBody(mainParent, panel, item, initIndex) {


    var divContent = document.createElement("DIV");
    divContent.classList.add('content__container_widgets_tile_content');
    divContent.style = "padding: 1em;cursor: pointer";

    var divIcon = document.createElement("DIV");
    divIcon.classList.add('content__container_widgets_tile_content_icon');

    var i = document.createElement("I");
    i.classList.add(item.preceedingIcon);
    i.classList.add(item.icon);
    divIcon.appendChild(i);

    var divInfo = document.createElement("DIV");
    divInfo.classList.add('content__container_widgets_tile_content_info');
    divInfo.style = "padding: 10px 0;";

    var h1 = document.createElement("H1");
    h1.innerText = item.name;
    divInfo.appendChild(h1);


    divContent.appendChild(divIcon);
    divContent.appendChild(divInfo);

    divContent.addEventListener('click', function () {
        const isWidgetDraggable = checkMandatoryParent(item.type);

        /* do stuff here*/
        removeElementById("widget_modal")

      if(!isWidgetDraggable) {
        return false;
      }
        // createLink();
        // addComponent(mainParent, '--blue', item.preceedingIcon, item.icon, item.name, Date.now(), item.type)
        // addPlusElement(mainParent);

        addComponentToStorage(getRandomIndex(), item.type, initIndex);
    }, false);

    panel.appendChild(divContent);
    return panel;

}

function branchWidget(parent, item) {
    //removeElementById("add_element");
    //createLink();
    const numberOfBranches = item.nodes ? item.nodes.length : 0;
    const divBranch = document.createElement("DIV");
    divBranch.classList.add('branchchart');
    divBranch.style = "display: flex;justify-content: center;align-items: flex-start;";

    const table = document.createElement("table");
    table.setAttribute("cellpadding", 0);
    table.setAttribute("cellspacing", 0);
    table.setAttribute("border", 0);

    const tbody = document.createElement("tbody");

    const trNodeCells = document.createElement("tr");
    trNodeCells.classList.add('node-cells');

    const tdNodeCell = document.createElement("td");
    tdNodeCell.classList.add('node-cell');
    // tdNodeCell.setAttribute("cellspacing", 0);
    tdNodeCell.setAttribute("colspan", 4 * numberOfBranches);

    const node = document.createElement("DIV");
    node.classList.add('node');

    const preceedingIcon = "fas";
    const icon = "fa-code-branch";
    item.type = "FS_BRANCH";
    item.skip = true;

    const comp = addComponent(false, parent, '--blue', preceedingIcon, icon, "branch_widget", item);
    node.appendChild(comp);

    tdNodeCell.appendChild(node);
    trNodeCells.appendChild(tdNodeCell);

    tbody.appendChild(trNodeCells);

    //---------------------------------------------------
    if (numberOfBranches > 1) {
        var tr = document.createElement("tr");

        var td = document.createElement("td");
        td.setAttribute("colspan", 2 * numberOfBranches);

        var div = document.createElement("DIV");
        div.classList.add('downn');

        td.appendChild(div);
        tr.appendChild(td);

        tbody.appendChild(tr);


        var tr = document.createElement("tr");
        tr.style = "height:50px;";

        var rightTd = document.createElement("td");
        rightTd.classList.add('right');
        rightTd.innerHTML = "&nbsp;";
        tr.appendChild(rightTd);

        for (var i = 1; i <= (numberOfBranches * 2) - 2; i++) {
            var direction = i % 2 == 0 ? "right" : "left";
            var td = document.createElement("td");
            td.classList.add(direction, 'top');
            td.innerHTML = "&nbsp;";
            tr.appendChild(td);
        }

        var leftTd = document.createElement("td");
        leftTd.classList.add('left');
        tr.appendChild(leftTd);

        tbody.appendChild(tr);

        var tr = document.createElement("tr");

        //create the branch nodes
        //each node {condition : {variable, operator, value}, component: {}}

        item.nodes && item.nodes.map((item, i) => {


            var containerTd = document.createElement("td");
            containerTd.classList.add('node-container');
            containerTd.setAttribute("colspan", 2);
            containerTd.appendChild(innerBranch(item))
            tr.appendChild(containerTd);
        })

        tbody.appendChild(tr);


        var tr = document.createElement("tr");
        tr.style = "height:50px;";

        var rightTd = document.createElement("td");
        rightTd.classList.add('right');
        rightTd.innerHTML = "&nbsp;";
        tr.appendChild(rightTd);

        for (var i = 1; i <= (numberOfBranches * 2) - 2; i++) {
            var direction = i % 2 == 0 ? "right" : "left";
            var td = document.createElement("td");
            td.classList.add(direction, 'down');
            td.innerHTML = "&nbsp;";
            tr.appendChild(td);
        }

        var leftTd = document.createElement("td");
        leftTd.classList.add('left');
        tr.appendChild(leftTd);

        tbody.appendChild(tr);



        var tr = document.createElement("tr");

        var td = document.createElement("td");
        td.setAttribute("colspan", numberOfBranches * 2);

        var div = document.createElement("DIV");
        div.classList.add('downn');

        td.appendChild(div);
        tr.appendChild(td);

        tbody.appendChild(tr);
    }


    table.appendChild(tbody);


    divBranch.appendChild(table);


    parent.appendChild(divBranch);


    //parent = addPlusElement(parent, item.index);

    return parent;

}

function innerBranch(branchNode) {
    var table = document.createElement("table");
    table.setAttribute("cellpadding", 0);
    table.setAttribute("cellspacing", 0);
    table.setAttribute("border", 0);
    var tbody = document.createElement("tbody");

    var trNodeCells = document.createElement("tr");
    trNodeCells.classList.add('node-cells');

    var tdNodeCell = document.createElement("td");
    tdNodeCell.classList.add('node-cell');
    tdNodeCell.setAttribute("colspan", 2);
    var span = document.createElement("span");
    span.style = "font-size: 12px";
    var condition = branchNode.variable + " " + branchNode.operator + " " + branchNode.value;
    span.innerText = condition;
    tdNodeCell.appendChild(span);
    trNodeCells.appendChild(tdNodeCell);

    tbody.appendChild(trNodeCells);


    var trNodeCells = document.createElement("tr");
    trNodeCells.classList.add('node-cells');

    var tdNodeCell = document.createElement("td");
    tdNodeCell.classList.add('node-cell');
    tdNodeCell.setAttribute("colspan", 2);



    //branch components listing
    branchNode && branchNode.components && branchNode.components.map((component, i) => {
        widgetsList.filter(obj => {
            obj.itemsList.filter(ob => {
                if (ob.type === component.type) {
                    if (component.type === "FS_BRANCH") {
                        branchWidget(tdNodeCell, component);
                        addPlusElement(tdNodeCell, component.index);
                    }else{
                        var comp = addComponent(false, parent, '--blue', ob.preceedingIcon, ob.icon, ob.name, component);
                        tdNodeCell.appendChild(comp);
                        if (component.type !== "FS_END") {
                            addPlusElement(tdNodeCell, component.index);
                        }
                    }

                    i + 1 < branchNode.components.length && createLink(tdNodeCell);
                }
            })
        })
    })


    trNodeCells.appendChild(tdNodeCell);
    tbody.appendChild(trNodeCells);


    table.appendChild(tbody);
    return table;
}

function initiateAccordion() {
    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
        if (listeners[acc[i]]) {
            acc[i].removeEventListener('click', listeners[acc[i]]);
        }
        acc[i].addEventListener('click', clickAccordion);
        listeners[acc[i]] = clickAccordion;

    }

    var parentWidget = document.getElementById("parent_widget");
    parentWidget && parentWidget.addEventListener('click', clickParentWidget);

    var variablesInfo = document.getElementById("variables_heading");
    variablesInfo && variablesInfo.addEventListener('click', clickVariableHeadingAccordion);






}

function clickAccordion() {
    /* Toggle between adding and removing the "active" class,
    to highlight the button that controls the panel */
    this.classList.toggle("active");

    /* Toggle between hiding and showing the active panel */
    var panel = this.nextElementSibling;
    if (panel.style.display === "block") {
        panel.style.display = "none";
    } else {
        panel.style.display = "block";
    }
}

function clickVariableHeadingAccordion() {
    this.classList.toggle("active");

    var panel = this.nextElementSibling;
    if (panel.style.display === "flex") {
        panel.style.display = "none";
    } else {
        panel.style.display = "flex";
    }
}


function clickParentWidget() {
    /* Toggle between adding and removing the "active" class,
    to highlight the button that controls the panel */
    this.classList.toggle("active");

    /* Toggle between hiding and showing the active panel */
    document.querySelectorAll('.content__container_widgets_tile').forEach(panel => {
        if (panel.style.display === "block") {
            panel.style.display = "none";
        } else {
            panel.style.display = "block";
        }
    });
}


function removeElementById(id) {
    var element = document.getElementById(id);
    element && element.remove();
}
function initiateAccordionWidget() {
    var acc = document.getElementsByClassName("accordion_widget");
    var i;

    for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function () {
            /* Toggle between adding and removing the "active" class,
            to highlight the button that controls the panel */
            this.classList.toggle("active");

            /* Toggle between hiding and showing the active panel */
            var panel = this.nextElementSibling;
            if (panel.style.display === "block") {
                panel.style.display = "none";
            } else {
                panel.style.display = "block";
            }
        });
    }
}


function removeListener() {
    document.querySelectorAll('.droptarget').forEach(item => {
        item.replaceWith(item.cloneNode(true));
    });
    document.querySelectorAll('.draggable').forEach(item => {
        item.replaceWith(item.cloneNode(true));
    });
}

function dropTargetListener() {
    removeListener();
    let images = {};
    let isWidgetDraggable = true;
    document.querySelectorAll('.draggable').forEach(item => {
        var url = "";
        if (item.id === "send_whatsapp_message") {
            url = "/assets/img/unifonic_sms_drag.png";
        } else if (item.id === "send_sms") {
            url = "/assets/img/unifonic_drag_sms.jpg";
        } else if (item.id === "wait") {
            url = "/assets/img/unifonic_hourglass_drag.png";
        } else {
            url = "/assets/img/unifonic_default_drag.png";
        }
        let img = new Image();
        img.src = url;
        images[item.id] = img;


        item.addEventListener('dragstart', event => {
          let widgetType = event.target.title;

          isWidgetDraggable = checkMandatoryParent(widgetType);
          if (!isWidgetDraggable) {
            return false;
          }

            let id = event.target.id;
            let img = images[event.target.id];

            event.dataTransfer.setDragImage(img, 0, 0);
            event.dataTransfer.setData('id', id);
            event.dataTransfer.setData('type', widgetType);
        });
    });

  if(!isWidgetDraggable) {
    return false;
  }

    document.querySelectorAll('.droptarget').forEach(item => {


        item.addEventListener('dragover', event => {
            event.preventDefault()
        });
        item.addEventListener('dragleave', event => {
            item.classList.remove('content__container_tools_icon_big');
            item.classList.add('content__container_tools_icon');
            event.target.style.border = "";
        });
        item.addEventListener('dragenter', event => {
            item.classList.remove('content__container_tools_icon');
            item.classList.add('content__container_tools_icon_big');

            event.target.style.border = "3px dotted green";
        });

      if(!isWidgetDraggable) {
        return false;
      }


        item.addEventListener('drop', (event) => dropWidget(event, item))
        item.addEventListener('click', function () {
            /* do stuff here*/
            // createLink();
            var parent = document.getElementById("content__container_branch");
            var thisId = this.title;
            createWidgetModal(parent, thisId);
            initiateAccordionWidget();

        });


    });
}

function dropWidget(event, item) {
  const isWidgetDraggable = checkMandatoryParent( event.dataTransfer.getData("type"));
  if (!isWidgetDraggable) {
    return false;
  }
    const parent = document.getElementById("content__container_branch");
    createLink(parent);
    item.classList.remove('content__container_tools_icon_big');
    item.classList.add('content__container_tools_icon');
    event.target.style.border = "";

    var type = event.dataTransfer.getData("type");
    // var id = event.dataTransfer.getData("id");


    addComponentToStorage(getRandomIndex(), type, event.target.title);
}



function dragStart(event) {
  event.dataTransfer.setData("title", event.target.getAttribute('title'));
}

function drop(event) {

  dropVariable(event);

}

function dropVariableTargetListener() {

    let images = {};
    document.querySelectorAll('.draggableVariable').forEach(item => {
        var url = "/assets/img/unifonic_default_drag.png";
        let img = new Image();
        img.src = url;
        images[item.id] = img;

        item.addEventListener('dragstart', event => {

            var title = event.target.title;
            let img = images[event.target.id];

            event.dataTransfer.setDragImage(img, 0, 0);
            event.dataTransfer.setData('title', title);
        })
    })


    document.querySelectorAll('.droptargetVariable').forEach(item => {

        item.addEventListener('dragover', event => {
            event.preventDefault()
        })
        item.addEventListener('dragleave', event => {
            event.target.style.border = "";
        })
        item.addEventListener('dragenter', event => {

            event.target.style.border = "3px dotted green";
        })

        if (listeners[item]) {
            item.removeEventListener('drop', listeners[item]);
        }
        item.addEventListener('drop', dropVariable)
        listeners[item] = dropVariable;
    })
}


function dropVariable(event) {


    event.target.style.border = "";
    var title = event.dataTransfer.getData("title");

    //customization of how this should be shown on the UI, upon saving, this should be converted to what the backend understands
    //also upon retrieving from backend, the frontend should transform back to expected design

    var variableInput = document.getElementById(event.target.id);
    variableInput.innerHTML = variableInput.innerHTML + variableConverterPlaceHolder.frontendStart + title + variableConverterPlaceHolder.frontendFinish;

    // variableInput.value = variableInput.value + title;

}


function getRandomIndex() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}


var scale = 1.0;
var scaleMultiplier = 0.8;

// add button event listeners
document.getElementById("plus") && document.getElementById("plus").addEventListener("click", function () {
    scale /= scaleMultiplier;
    document.getElementById('canvas').style.zoom = scale;
}, false);

document.getElementById("minus") && document.getElementById("minus").addEventListener("click", function () {
    scale *= scaleMultiplier;
    document.getElementById('canvas').style.zoom = scale;
}, false);
document.getElementById("reset") && document.getElementById("reset").addEventListener("click", function () {
    rescaleCanvas();
}, false);

function rescaleCanvas() {
    scale = 0.9;
    document.getElementById('canvas').style.zoom = scale;
    document.getElementById('canvas').style.top = "16px";
    document.getElementById('canvas').style.left = "40%";
}


var clearFlowElement = document.getElementById("clearFlow");
if (clearFlowElement) {
    clearFlowElement.addEventListener('click', clearFlowLocally)
}

function clearFlowLocally() {
    localStorage.setItem("flowList", JSON.stringify([]));
    rebuildFlow([], parentWidgetType);
}


dragElement(document.getElementById(("canvas")));
function dragElement(elmnt) {
    if (elmnt) {
        var pos1 = 0,
            pos2 = 0,
            pos3 = 0,
            pos4 = 0;
        elmnt.onmousedown = dragMouseDown;

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;

            elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";


        }

        function closeDragElement() {
            /* stop moving when mouse button is released:*/
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }

}

function validateInput(variableElement, inputType) {

  let inputValue;

  if(inputType === 'input') {
    inputValue = variableElement.value;
  } else {
    inputValue = variableElement.innerHTML;
  }

  let invalidInput = false;
  if (inputValue === '' || inputValue === undefined) {
    const elemId = variableElement.getAttribute('id');
    const validationDiv = document.getElementById(elemId + '_validation');
    if (validationDiv !== null) {
      validationDiv.classList.remove('invisible');
    }
    invalidInput = true;
  } else {
    const elemId = variableElement.getAttribute('id');
    const validationDiv = document.getElementById(elemId + '_validation');
    if (validationDiv !== null) {
      validationDiv.classList.add('invisible');
    }
  }
  return invalidInput;
}

function checkMandatoryParent(widgetType) {

  let mandatoryParentType = null;
  for (let widgetGroup of widgetsList) {
    for (let item of widgetGroup.itemsList) {
      if (item.type === widgetType) {
        mandatoryParentType = item.mandatoryParent;
        break;
      }
    }
    if (mandatoryParentType !== null) {
      break;
    }
  }

  if (mandatoryParentType === null || mandatoryParentType === '') {
    return true;
  }

  let isMandatoryParentMissing = true;
  const flowList = JSON.parse(localStorage.getItem('flowList'));
  for (let i = flowList.length - 1; i >= 0; i--) {
    if (flowList[i].type !== 'FS_BRANCH') {
      if (flowList[i].type === mandatoryParentType) {
        isMandatoryParentMissing = false;
        break;
      }

      if (mandatoryParentType === 'FS_VOICE' && flowList[i].type === 'FS_VOICE_END_CALL') {
        break;
      }
    } else {
      for (let node of flowList[i].nodes) {
        let filteredComponents = node.components.filter(x => {
          return x.type === 'FS_VOICE';
        });

        if(filteredComponents.length > 0) {
          isMandatoryParentMissing = false;
          break;
        }
      }
    }

  }

  return !isMandatoryParentMissing
}
